import { action, observable, makeObservable } from 'mobx';
import queryString from 'query-string';
import { isEmpty } from 'lodash';

import UserStore from './UserStore';
import PaymentStore from './PaymentStore';
import { toast } from '../utils';
import NotificationStore from './NotificationStore';
import { getLanguages } from '../actions/languages';
import { getAppInfo } from '../actions/app';
import { getTranslates, getDefaultTranslates } from '../actions/translates';
import { sendAmplitudeSessionStart } from '@utils/amplitude';
import { getUserCountry } from '@actions/user';

class AppStore {
  ping_interval = null;
  @observable ready = false;
  @observable app_info = null;
  @observable translations = {};
  @observable defaultTranslations = {};
  @observable languages = null; // Объект {
  //          ru : {name: "Russian", native: "Русский", rus: "Русский"}
  //          en : {name: "English", native: "English", rus: "Английский"}
  //        }
  @observable languagesCodes = [];
  @observable lang = null; // Объект {name: "Russian", native: "Русский", rus: "Русский"}
  @observable ln = null; // Строка 'ru'
  @observable is_auth = false;

  @observable subscribed = false;

  @observable appEmails = {
    info: 'info@vellness.io',
    customer: 'customer@vellness.io',
    support: 'support@vellness.io',
  };

  @observable appContacts = [];

  @observable userCountryCode =
    localStorage.getItem('countryByIp')?.toUpperCase() || 'defaultCountry';

  @observable eurDefaultTariff = {
    stripe: 'trial.3.days.vellness.eur',
    payPal: 'P-63510465KC1961426MHF6EYY',
    price: 0.99,
    currency: '€',
    currencyCode: 'EUR',
  };

  @observable cachedParams = [
    {
      name: 'lang',
    },
    {
      name: 'ms',
    },
    {
      name: 'isMobileApplication',
    },
    {
      name: 'b',
    },
    {
      name: 'paymentTariff',
    },
    {
      name: 'devtest',
    },
    {
      name: 'utm_source',
      static: true,
      saveInUrl: true,
    },
    {
      name: 'utm_medium',
      static: true,
      saveInUrl: true,
    },
    {
      name: 'utm_campaign',
      static: true,
      saveInUrl: true,
    },
    {
      name: 'utm_term',
      static: true,
      saveInUrl: true,
    },
    {
      name: 'utm_content',
      static: true,
      saveInUrl: true,
    },
    {
      name: 'esub',
      static: true,
      saveInUrl: true,
    },
    {
      name: 'pub_id',
      static: true,
      saveInUrl: true,
    },
    {
      name: 'ab',
    },
    { name: 'vybor_ceni' },
    {
      name: 'devMode',
    },
    {
      name: 'fpxl',
      static: true,
    },
    {
      name: 'showBlogger',
    },
    {
      name: 'ttq',
      static: true,
    },
    {
      name: 'phone',
    },
    {
      name: 'abGift',
    },
    {
      name: 'paymentType',
    },
    {
      name: 'abGpay',
    },
    {
      name: 'present_mode',
    },
    {
      name: 'card',
    },
    {
      name: 'cardgpayapay',
    },
    {
      name: 'quizEmail',
      static: true,
    },
  ];

  @observable inputParameters = null;

  @action init = async () => {
    try {
      // save some params to cache
      this.inputParameters = window.location.search;
      const params = queryString.parse(this.inputParameters);

      if (!localStorage.getItem('countryByIp')) {
        const userCountry = (await getUserCountry()) || {
          data: {
            isoCode: 'EN',
          },
        };
        const userCountryCode = userCountry.data.isoCode.toUpperCase();
        localStorage.setItem('countryByIp', userCountryCode);
        this.userCountryCode = userCountryCode;
        const libsCountriesList = ['US', 'CA'];
        if (!libsCountriesList.includes(userCountryCode)) {
          localStorage.setItem('ms', 'metr');
        }
      } else {
        this.userCountryCode = localStorage.getItem('countryByIp');
      }

      let needToRedirect = false;

      // eslint-disable-next-line no-unused-vars
      for (const prm of this.cachedParams) {
        if (prm.name in params) {
          if (!prm.static || !localStorage.getItem(prm.name)) {
            localStorage.setItem(prm.name, params[prm.name]);
          }
          if (!prm.saveInUrl) {
            delete params[prm.name];
          }

          needToRedirect = true;
        }
      }

      if (needToRedirect) {
        const queryStrWithoutParams = queryString.stringify(params);
        window.history.replaceState(
          {},
          document.title,
          window.location.href.replace(
            /\?.*$/,
            queryStrWithoutParams ? `?${queryStrWithoutParams}` : '',
          ),
        );
      }

      await this.getLangs();
      await PaymentStore.getTariff();
      // eslint-disable-next-line no-return-assign
      if (!localStorage.token) {
        return (this.ready = true);
      }
      await UserStore.get();
      await NotificationStore.get();
      if (localStorage.token) {
        this.is_auth = true;
        sendAmplitudeSessionStart(UserStore.user?.email);
      }
      this.ping();
    } catch (e) {
      localStorage.removeItem('token');
    }

    this.ready = true;
  };

  @action getLangs = async (auth) => {
    try {
      const res = await getLanguages();
      this.languages = res.data;

      const languagesArr = [];
      // eslint-disable-next-line guard-for-in,no-restricted-syntax,no-unused-vars
      for (const key in this.languages) {
        languagesArr.push(key);
      }
      this.languagesCodes = languagesArr;

      let ln = localStorage.getItem('lang') || localStorage.getItem('ln') || 'en';
      if (!this.languages[ln]) {
        ln = 'en';
        localStorage.setItem('lang', 'en');
      }

      await this.setLang(ln, auth);
    } catch (e) {
      toast(e.message);
    }
  };

  @action setLang = async (lang, auth) => {
    if (!this.app_info) {
      await this.getAppInfo();
    }
    const default_lang = this.app_info?.default_lang || 'en';
    if (!lang) {
      const locale = window.navigator.language;
      lang = locale ? locale.split('-')[0] : 'en';
    }
    this.lang = this.languages?.[lang] ||
      this.languages?.[default_lang] || {
        rus: 'Английский',
        name: 'English',
        native: 'English',
      };
    lang = this.languages?.[lang] ? lang : 'en';

    localStorage.setItem('lang', lang);
    await this.getTranslates();
    this.ln = lang;

    if (localStorage.token && UserStore?.user?.lang !== lang) {
      UserStore.updateUser({ lang }, true);
    }
    if (lang !== this.app_info?.default_lang || auth) {
      this.getAppInfo();
    }
    document.querySelector('html').setAttribute('lang', lang);
  };

  @action getAppInfo = async () => {
    try {
      const res = await getAppInfo();
      this.app_info = res.data;
      this.app_info.cabinet_link =
        this.app_info.cabinet_link?.slice(-1) === '/'
          ? this.app_info.cabinet_link
          : `${this.app_info.cabinet_link}/`;
      this.subscribed = res.data?.subscribe_status;
      this.appContacts = [
        {
          filedName: 'Worldwide',
          value: this.app_info?.company_address || 'ADDRESS1',
        },
        {
          filedName: 'EMAIL',
          value: this.app_info?.support_email || 'INFO_EMAIL',
        },
      ];
    } catch (e) {
      toast(e.message);
    }
  };

  @action getTranslates = () => {
    const promise = getTranslates().then((data) => (this.translations = data));
    if (this.ln !== 'en' && isEmpty(this.defaultTranslations)) {
      getDefaultTranslates().then((data) => (this.defaultTranslations = data));
    }
    return promise;
  };

  @action changeAppInfo = (key, value) => {
    this[key] = value;
  };

  ping = () => {
    this.ping_interval = setInterval(async () => {
      try {
        await this.getAppInfo();
        await UserStore.get();
        await NotificationStore.get();
      } catch (e) {
        if (e.code === 401) {
          this.clearPing();
        }
      }
    }, 60000);
  };

  clearPing = () => {
    clearInterval(this.ping_interval);
    this.ping_interval = null;
  };

  constructor() {
    makeObservable(this);
  }
}

export default new AppStore();
