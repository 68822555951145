import React, { Component } from 'react';
import Layout from '../components/Layout';
import { Route, Switch } from 'react-router';
import { inject, observer } from 'mobx-react';

import PrivacyPolicy from '../components/ContentPages/PrivacyPolicy';
import TermsService from '../components/ContentPages/TermsService';
import Contacts from '../components/ContentPages/Contacts';
import HIPAA from '../components/ContentPages/HIPAA';
import CCPA from '../components/ContentPages/CCPA';
import ResearchPolicy from '../components/ContentPages/ResearchPolicy';
import COF from '@components/ContentPages/COF';
import RoutesLang from '@utils/RoutesLang';
import RefundPolicy from '@components/ContentPages/RefundPolicy';

@inject('appStore')
@observer
class ContentPagesContainer extends Component {
  render() {
    const { appStore } = this.props;
    // vellness.io //vellness.io/pros
    return (
      <Layout
        user_is_authorized={this.props.authorized}
        layoutClassName="content-page"
        with_container={false}
        withCookieModal
      >
        <div
          className={`content-page__container ${
            this.props.authorized ? 'content-page__container_auth' : ''
          }`}
        >
          <div className="content-page__limit">
            <Switch>
              <Route
                exact
                path={RoutesLang.privacy}
                render={() => <PrivacyPolicy emails={appStore.appEmails} />}
              />
            </Switch>
            <Switch>
              <Route exact path={RoutesLang.terms} render={() => <TermsService />} />
            </Switch>
            <Switch>
              <Route exact path={RoutesLang.contacts} render={() => <Contacts />} />
            </Switch>
            <Switch>
              <Route
                exact
                path={RoutesLang.hipaaNotice}
                render={() => <HIPAA emails={appStore.appEmails} />}
              />
            </Switch>
            <Switch>
              <Route
                exact
                path={RoutesLang.ccpa}
                render={() => <CCPA emails={appStore.appEmails} />}
              />
            </Switch>
            <Switch>
              <Route exact path={RoutesLang.researchPolicy} render={() => <ResearchPolicy />} />
            </Switch>
            <Switch>
              <Route exact path={RoutesLang.cof} render={() => <COF />} />
            </Switch>
            <Switch>
              <Route exact path={RoutesLang.refund} render={() => <RefundPolicy />} />
            </Switch>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ContentPagesContainer;
