import React from 'react';
import { translate } from '@/utils';
import { useStores } from '@/store';
import { Helmet } from 'react-helmet';
import AppStore from '@store/AppStore';

const CCPA = () => {
  const { app_info } = useStores().appStore;
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: translate('CCPA_CONTENT')
            .replace(/{{project_name}}/g, app_info.project_name || 'VELLNESS')
            .replace(/{{company_name}}/g, app_info.company_name || 'Lunara inc')
            .replace(/{{support_email}}/g, app_info.support_email || 'support@vellness.io'),
        }}
      />
      <Helmet>
        <link rel="canonical" href="https://lunaraapp.com/en/ccpa" />
        <title>
          {translate('VELLNESS_META_TITLE_CCPA').replace(
            /{{project_name}}/g,
            AppStore.app_info?.project_name || 'Vellness',
          )}
        </title>
        <meta
          name="description"
          content={translate('VELLNESS_META_DESCRIPTION_CCPA').replace(
            /{{project_name}}/g,
            AppStore.app_info?.project_name || 'VELLNESS',
          )}
        />
      </Helmet>
    </>
  );
};

export default CCPA;
