import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { getPublicNewsList } from '@actions/news';
import Slider from 'react-slick';
import MediaQuery from 'react-responsive/src/Component';
import breakpoints from '@utils/breakpoints';
import { translate } from '@/utils';

import girlImage from '@images/main-girl.png';
import cup from '@images/cup.png';
import bubbles from '@images/bubbles.png';
import dna from '@images/dna.png';
import cake from '@images/cake.png';
import dumb from '@images/dumb.png';
import Layout2 from '@components/Layout2';
import ErrorIndicator from '@components/ErrorIndicator';
import MainSection from '@components/HomePage/MainSection';
import CommonSection from '@components/HomePage/CommonSection';
import FixedLinks from '@components/HomePage/FixedLinks';
import AboutItem from '@components/HomePage/AboutItem';
// import AppSection from '@components/HomePage/AppSection';
import NewsItem from '@components/News/NewsItem';
import TariffSection from '@components/HomePage/TariffSection';

@inject('commonStore', 'appStore')
@observer
class HomeContainer extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      firstSectionIsActive: null,
      lastSectionIsActive: null,
      currentSlide: null,
      sliderLength: null,
      pageLang: this.props.appStore.ln,
      aboutData: [
        {
          image: cup,
          labelKey: 'IT_KNOWS_PSYCHOLOGY',
          textKey: 'WORKS_WITH_YOUR_BELIEVES',
        },
        {
          image: bubbles,
          labelKey: 'ITS_PERSONAL',
          textKey: 'HE_COURSE_IS_CUSTOMIZABLE',
        },
        {
          image: dna,
          labelKey: 'IT_TRACKS',
          textKey: 'TRACKING_YOUR_DAILY_ACTIVITY',
        },
        {
          image: cake,
          labelKey: 'REAL_CHEF',
          textKey: 'IT_HELPS_YOU_GROUP',
        },
        {
          image: dumb,
          labelKey: 'IT_TRAINS_YOU',
          textKey: 'BASED_ON_YOUR_GOAL',
        },
      ],
      blogData: [],
    };
  }

  async componentDidMount() {
    const { commonStore } = this.props;
    document.addEventListener('wheel', this.swipeOnWheel);
    document.body.classList.add('main-page');
    commonStore.setFullPageSliderWheelStatus(true);
    commonStore.setFullPageSlideIndex(0);
    this.setState({
      firstSectionIsActive: true,
      lastSectionIsActive: false,
      sliderLength: this.slider.props.children.length,
      currentSlide: 0,
    });
    this.setPublicNews();
  }

  async componentDidUpdate() {
    const { appStore } = this.props;
    if (this.state.pageLang !== appStore.ln) {
      this.setPublicNews();
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('main-page');
    document.removeEventListener('wheel', this.swipeOnWheel);
    this.setState({
      firstSectionIsActive: null,
      lastSectionIsActive: null,
    });
    this._isMounted = false;
  }

  setPublicNews = async () => {
    this._isMounted = true;
    const { appStore } = this.props;
    try {
      const res = await getPublicNewsList();
      if (this._isMounted) {
        this.setState({ pageLang: appStore.ln, blogData: res.data });
      }
    } catch (e) {
      throw e;
    }
  };

  swipeOnWheel = (e) => {
    const { commonStore } = this.props;
    if (
      window.innerWidth >= parseInt(breakpoints['sm-plus'], 10) &&
      commonStore.fullPageSliderWheelEnabled &&
      !commonStore.stopHomePageWheel
    ) {
      if (e.deltaY < 0 && this.state.currentSlide !== 0) {
        this.slider.slickPrev();
      } else if (e.deltaY > 0 && this.state.currentSlide + 1 !== this.state.sliderLength) {
        this.slider.slickNext();
      }
    }
  };

  render() {
    const { commonStore } = this.props;
    const { appStore } = this.props;

    if (this.error) {
      return <ErrorIndicator error={this.error} />;
    }

    if (
      (commonStore.fullPageSlideIndex || commonStore.fullPageSlideIndex === 0) &&
      window.innerWidth >= parseInt(breakpoints['sm-plus'], 10) &&
      this.slider
    ) {
      this.slider.slickGoTo(commonStore.fullPageSlideIndex);
    }

    const settings = {
      dots: true,
      infinite: false,
      vertical: true,
      verticalSwiping: true,
      swipeToSlide: true,
      cssEase: 'linear',
      speed: 600,
      dotsClass: 'full-page__dots',
      draggable: true,
      onInit: () => {
        document.addEventListener('wheel', this.swipeOnWheel);
        this.setState({ firstSectionIsActive: true });
        this.setState({ lastSectionIsActive: false });
      },
      beforeChange: (currentSlide, nextSlide) => {
        if (currentSlide === 0 && nextSlide !== 0) {
          this.setState({ firstSectionIsActive: false });
        }
        this.setState({
          lastSectionIsActive: nextSlide + 1 === this.state.sliderLength,
        });
      },
      afterChange: (currentSlide) => {
        if (currentSlide === 0) {
          this.setState({ firstSectionIsActive: true });
        }
        this.setState({ currentSlide });
      },
      responsive: [
        {
          breakpoint: parseInt(breakpoints.xs, 10) + 1,
          settings: 'unslick',
        },
      ],
    };

    return (
      <>
        <Layout2
          user_is_authorized={false}
          layoutClassName={`home-page ${
            this.state.lastSectionIsActive ? 'home-page_footer-show' : ''
          }`}
          withCookieModal
        >
          <div className="full-page">
            <MediaQuery minWidth={breakpoints['sm-plus']}>
              <div className="full-page__column">
                {this.state.lastSectionIsActive ? (
                  <div
                    onClick={() => {
                      this.slider.slickGoTo(0);
                    }}
                    className="full-page__open-first slick-next"
                  />
                ) : null}
              </div>
            </MediaQuery>
            <div className="full-page__column full-page__column_right">
              <MediaQuery minWidth={breakpoints['sm-plus']}>
                <img
                  open={this.state.firstSectionIsActive}
                  className="full-page__girl"
                  src={girlImage}
                  alt=""
                />

                <div open={this.state.firstSectionIsActive} className="full-page__decoration">
                  <span />
                  <span />
                  <span />
                </div>
                <FixedLinks
                  firstSectionIsActive={this.state.firstSectionIsActive}
                  lastSectionIsActive={this.state.lastSectionIsActive}
                />
              </MediaQuery>
              <Slider
                {...settings}
                className="full-page__slider"
                ref={(slider) => (this.slider = slider)}
              >
                <div className="full-page__slide full-page__slide_main">
                  <MainSection />
                </div>
                <div className="full-page__slide full-page__slide_about">
                  <CommonSection
                    mobileItemLimit={3}
                    classNameFromSlider="about"
                    classModifications="common-section_about"
                    label={translate('WHY_DOES_Vellness').replace(
                      /{{project_name}}/g,
                      appStore.app_info?.project_name || 'Vellness',
                    )}
                    text={translate('EVERYTHING_TO_HELP')}
                  >
                    {this.state.aboutData.map((item, i) => {
                      return <AboutItem key={i} data={item} />;
                    })}
                  </CommonSection>
                </div>
                {/* <div className="full-page__slide full-page__slide_app">
                  <AppSection />
                  </div> */}
                <div className="full-page__slide full-page__slide_blog">
                  <CommonSection
                    mobileItemLimit={2}
                    classNameFromSlider="blog"
                    classModifications="common-section_blog"
                    label={translate('OUR_BLOG')}
                  >
                    {this.state.blogData.map((news) => {
                      return (
                        <NewsItem custom news={news} newsList={this.state.blogData} key={news.id} />
                      );
                    })}
                  </CommonSection>
                </div>
                <div className="full-page__slide  full-page__slide_last full-page__slide_tariff">
                  <TariffSection />
                </div>
              </Slider>
            </div>
          </div>
        </Layout2>
      </>
    );
  }
}

export default HomeContainer;
