import React from 'react';
import { useStores } from '@/store';
import { translate } from '@/utils';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import AppStore from '@store/AppStore';

const Contacts = () => {
  const { appContacts } = useStores().appStore;

  const changedContacts = appContacts
    .filter((contact) => contact.filedName !== 'PHONE_NUMBER')
    .map((contact) => {
      if (contact.filedName === 'EMAIL') {
        if (window.location.host === 'VELLNESSie.com') {
          contact.value = 'support@VELLNESSie.com';
        }
      }

      if (contact.filedName === 'Worldwide') {
        if (window.location.host === 'VELLNESSie.com') {
          contact.value = '340 South Lemon Ave 7997, Walnut, CA 91789, USA';
        }
      }

      return contact;
    });

  return (
    <>
      <div className="label">{translate('CONTACT')}</div>
      <hr />

      {changedContacts.map((contact, i) => {
        return (
          <div key={i}>
            <div className="sub-label">{translate(contact.filedName)}</div>
            {contact.value && (
              <>
                {contact.filedName === 'Email' ? (
                  <a href={`mailto:${translate(contact.value)}`}>{translate(contact.value)}</a>
                ) : (
                  <p dangerouslySetInnerHTML={{ __html: contact.value }} />
                )}
              </>
            )}
          </div>
        );
      })}
      <Helmet>
        <link rel="canonical" href="https://lunaraapp.com/en/contacts" />
        <title>
          {translate('VELLNESS_META_TITLE_CONTACTS').replace(
            /{{project_name}}/g,
            AppStore.app_info?.project_name || 'Vellness',
          )}
        </title>
        <meta
          name="description"
          content={translate('VELLNESS_META_DESCRIPTION_CONTACTS').replace(
            /{{project_name}}/g,
            AppStore.app_info?.project_name || 'Vellness',
          )}
        />
      </Helmet>
    </>
  );
};

export default observer(Contacts);
