import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import useRouter from '@/hooks/useRouter';
import { useStores } from '@/store';

const RedirectRouteToLang = ({ component: Component, ...rest }) => {
  const [redirectStatus, setRedirectStatus] = useState(true);
  const [redirectSearchParams, setRedirectSearchParams] = useState(true);
  // ищем язык в локалсторадже
  const langLocale = localStorage.getItem('lang');
  const [pathWithLang, setPathWithLang] = useState(langLocale || 'en');
  const router = useRouter();
  const { languages, setLang, inputParameters } = useStores().appStore;

  const languagesArr = [];
  // eslint-disable-next-line guard-for-in,no-restricted-syntax,no-unused-vars
  for (const key in languages) {
    languagesArr.push({ ...languages[key], code: key });
  }
  // находим в массиве языков язык  из адресной строки
  const findLangUrl = (lang) => {
    return languagesArr.find((arrLang) => lang === arrLang.code);
  };
  const searchParams = new URLSearchParams(inputParameters);
  const langSearchParams = searchParams.get('lang');
  const langAddressBar = findLangUrl(router.location.pathname.split('/')[1])
    ? findLangUrl(router.location.pathname.split('/')[1]).code
    : langLocale || 'en';

  // const isAdvertising = () => {
  //   return searchParams.get('lang') || searchParams.get('ab');
  // };

  const stringWithoutLastElement = (str) => {
    if (str.slice(-1) === '/') {
      return str.slice(0, -1);
    }
    return str;
  };
  useEffect(() => {
    // const setRouterPath = (lang) => {
    //   setPathWithLang(
    //     `${router.location.pathname.replace(/[^/]*\/([^/]*)/, lang)}${router.location.search}`,
    //   );
    // };
    const isHomePage = router.location.pathname === '/' || router.location.pathname === '/pros';

    const processAdvertisingURL = (path, lang) => {
      const localLang = findLangUrl(lang)?.code || langLocale || 'en';

      const langPath = findLangUrl(path.split('/')[1]);
      const setting = {
        applySetting: (redirectSetting) =>
          redirectSetting ? setRedirectSetting() : setRedirectStatus(true),
        isRedirectSetting: false,
        pathWithLang: '',
      };
      const setRedirectSetting = () => {
        setRedirectStatus(false);
        setRedirectSearchParams(false);
      };
      setLang(localLang);
      const searchParams = `${router.location.search}`;
      const replaceLang = stringWithoutLastElement(
        router.location.pathname.replace(/[^/]*\/([^/]*)/, localLang),
      );

      if (isHomePage) {
        setting.pathWithLang = `${localLang}/${searchParams}`;
        setting.isRedirectSetting = true;
        setPathWithLang(setting.pathWithLang);
      } else if (langPath && redirectSearchParams) {
        setting.pathWithLang = `${
          router.location.pathname.split('/')[2]
            ? `${replaceLang}${searchParams}`
            : `${localLang}/${searchParams}`
        }`;
        setPathWithLang(setting.pathWithLang);
        setting.isRedirectSetting = true;
      } else if (!langPath && redirectSearchParams) {
        setting.pathWithLang = `${localLang}${router.location.pathname}${searchParams}`;
        setting.isRedirectSetting = true;
        setPathWithLang(setting.pathWithLang);
      } else {
        setting.pathWithLang = `${langLocale}/` || 'en/';
        setting.isRedirectSetting = false;
        setPathWithLang(setting.pathWithLang);
      }
      setting.applySetting(setting.isRedirectSetting);
    };
    // const processURL = (path, lang) => {
    //   if (findLangUrl(lang)) {
    //     // есть в есть чтото после языка в даресной строкеhttp://domain/en/test
    //     if (path.split('/')[2]) {
    //       // функция находит язык в адрсеной строке и меняет из адресной строки
    //       setRouterPath(langLocale || findLangUrl(lang).code);
    //     } else {
    //       //  если после языка ничего нет http://domain/en
    //       setPathWithLang(`${langLocale || findLangUrl(lang).code}${router.location.search}`);
    //     }
    //     // функция из стора для установки языка
    //     setLang(findLangUrl(lang).code);
    //     setRedirectStatus(true);
    //   } else {
    //     if (path.split('/')[2]) {
    //       setRouterPath(langLocale || 'en');
    //     } else {
    //       setPathWithLang(`${langLocale || findLangUrl(lang).code}${router.location.search}`);
    //     }
    //     setRedirectStatus(false);
    //   }
    // };

    // НУЖНО ДЛЯ СТАРЫХ РЕКЛАМНЫХ ССЫЛОК
    // if (isAdvertising()) {

    // } else {
    //   processURL(router.location.pathname, langAddressBar);
    // }

    processAdvertisingURL(router.location.pathname, langSearchParams || langAddressBar);
  }, [router.location.pathname, redirectSearchParams]);
  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          redirectStatus ? <Component {...props} /> : <Redirect to={`/${pathWithLang}`} />
        }
      />
    </>
  );
};

export default RedirectRouteToLang;
