import React, { useEffect } from 'react';
import { useStores } from '@/store';
import { gaMirror } from '@utils/metrics';
import { translate } from '@/utils';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  const { app_info } = useStores().appStore;
  useEffect(() => {
    gaMirror({
      event: 'policy_click',
      category: 'other',
      action: 'click',
      label: 'policy_click',
    });
  });
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: translate('PRIVACY_POLICY_CONTENT')
            .replace(/{{project_name}}/g, app_info.project_name || 'VELLNESS')
            .replace(/{{company_name}}/g, app_info.company_name || 'Lunara inc')
            .replace(/{{support_email}}/g, app_info.support_email || 'support@vellness.io')
            .replace(
              /{{company_address}}/g,
              app_info.company_address ||
                'SUBSTER UK LTD 41 Devonshire Street, Ground Floor, London, England, W1G 7AJ, reg. number: 13668795',
            ),
        }}
      />
      <Helmet>
        <link rel="canonical" href="https://lunaraapp.com/en/privacy" />
      </Helmet>
    </>
  );
};

export default PrivacyPolicy;
